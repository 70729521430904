import { EditorSDK } from '@wix/platform-editor-sdk';

import { APP_TOKEN } from '../../../constants';
import { getMembersAreaPage } from '../../../wrappers/pages';
import { getDataByAppDefId } from '../../../wrappers/tpa';
import { IntegrationApplicationWithWidgetId } from '../../types';

export const navigateToMembersAreaPage = async (editorSDK: EditorSDK) => {
  const membersAreaPage = await getMembersAreaPage(editorSDK);
  if (membersAreaPage) {
    await editorSDK.pages.navigateTo(APP_TOKEN, { pageRef: { id: membersAreaPage.id! } });
  }
};

export const createSubPageRoute = async (
  editorSDK: EditorSDK,
  definition: IntegrationApplicationWithWidgetId,
): Promise<string | undefined> => {
  if (definition.urlOverride || definition.method === 'addApplicationToSite') {
    return definition.urlOverride;
  }

  const appData = await getDataByAppDefId({ editorSDK, appDefinitionId: definition.appDefinitionId });

  if (!appData) {
    throw new Error('App data is missing for application ' + definition.appDefinitionId);
  }

  // @ts-expect-error: Platform types are missing
  const appDataComponent = appData.components.find((comp) => comp.componentId === definition.widgetId);
  return appDataComponent.name.toLowerCase().replace(' ', '-');
};
