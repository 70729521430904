import { BasicMenuItem, EditorReadyOptions, EditorSDK } from '@wix/platform-editor-sdk';
import { ExternalLink } from '@wix/document-services-types';

import { IntegrationApplicationWithWidgetId } from '../../types';
import {
  getMyAccountInstallDefinition,
  MENU_IDS,
  MY_ACCOUNT_APP_DEF_ID,
  MY_ACCOUNT_WIDGET_ID,
} from '../../../constants';
import { getMembersAreaPage } from '../../../wrappers/pages';
import { getDataByAppDefId } from '../../../wrappers/tpa';
import * as menusWrapper from '../../../wrappers/menus';
import { updateMenuItems } from '../../../wrappers/menus';

const createMenuItem = ({
  appBaseUrl,
  isPrivate,
  title,
  pageUriSEO,
  id,
}: {
  appBaseUrl: string;
  isPrivate: boolean;
  title: string;
  pageUriSEO: string;
  id: string;
}): BasicMenuItem => {
  const baseRoute = `https://{domain}/${appBaseUrl}`;
  const fullRoute = `${baseRoute}/{userName}/${pageUriSEO}`;
  const link: ExternalLink = { type: 'ExternalLink', url: fullRoute, target: '_self' };

  return {
    id,
    type: 'BasicMenuItem',
    label: title,
    link,
    isVisible: true,
    isVisibleMobile: true,
    items: [],
  };
};

const removeDuplicateItems = async (editorSDK: EditorSDK, menuId: string, newMenuItems: BasicMenuItem[]) => {
  const existingMenuItems = await menusWrapper.getMenuItems({ editorSDK, menuId });

  return newMenuItems.filter(
    (newMenuItem) => !existingMenuItems.some((existingMenuItem) => existingMenuItem.id === newMenuItem.id),
  );
};

const getDefinitionsByMenuId = (definitions: IntegrationApplicationWithWidgetId[], menuId: string) => {
  return menuId === MENU_IDS.SUB_MENU_ID
    ? definitions.filter((app) => app.showInMemberMenu)
    : definitions.filter((app) => app.showInLoginMenu);
};

const getAppsData = (editorSDK: EditorSDK, definitions: IntegrationApplicationWithWidgetId[]) => {
  return Promise.all(
    definitions.map((definition) => getDataByAppDefId({ editorSDK, appDefinitionId: definition.appDefinitionId })),
  );
};

const getMenuItems = async (
  editorSDK: EditorSDK,
  definitions: IntegrationApplicationWithWidgetId[],
  menuId: string,
) => {
  const membersAreaPage = await getMembersAreaPage(editorSDK);

  const appsData = await getAppsData(editorSDK, definitions);

  const menuItems = appsData.map((appData, index) => {
    const definition = definitions[index];

    if (!definition) {
      throw new Error('Page definition is missing when creating the login menu item');
    }

    // @ts-expect-error: Platform types are missing
    const appDataComponent = appData.components.find((comp) => comp.componentId === definition.widgetId);

    if (!appDataComponent) {
      throw new Error('App data for widget is missing when creating the login menu item');
    }

    return createMenuItem({
      appBaseUrl: membersAreaPage.pageUriSEO,
      isPrivate: !definition.social,
      title: appDataComponent.name,
      pageUriSEO: definition.urlOverride!,
      id: `${menuId}-${definition.widgetId}`,
    });
  });

  return removeDuplicateItems(editorSDK, menuId, menuItems);
};

const addMenuItems = async (
  editorSDK: EditorSDK,
  definitions: IntegrationApplicationWithWidgetId[],
  menuId: string,
) => {
  const filteredDefinitions = getDefinitionsByMenuId(definitions, menuId);
  const menuItems = await getMenuItems(editorSDK, filteredDefinitions, menuId);

  return menusWrapper.addMenuItems({ editorSDK, menuId, items: menuItems });
};

const addMyAccountMenuItem = async (editorSDK: EditorSDK, options: EditorReadyOptions, menuId: string) => {
  const myAccountDefinition = getMyAccountInstallDefinition(options.origin);
  const membersAreaPage = await getMembersAreaPage(editorSDK);

  const myAccountTPA = await getDataByAppDefId({ editorSDK, appDefinitionId: MY_ACCOUNT_APP_DEF_ID });
  // @ts-expect-error: Platform types are missing, and we have no other source for apps dev center texts
  const myAccountWidget = await myAccountTPA.widgets[MY_ACCOUNT_WIDGET_ID];

  if (!myAccountWidget) {
    throw new Error('My Account widget is not found');
  }

  const menuItem = createMenuItem({
    appBaseUrl: membersAreaPage.pageUriSEO,
    isPrivate: !myAccountDefinition.social,
    title: myAccountWidget.appPage.name,
    pageUriSEO: myAccountDefinition.urlOverride,
    id: `${menuId}-${MY_ACCOUNT_WIDGET_ID}`,
  });

  return updateMenuItems({ editorSDK, menuId, items: [menuItem] });
};

const addMyAccountLoginMenuItem = (editorSDK: EditorSDK, options: EditorReadyOptions) =>
  addMyAccountMenuItem(editorSDK, options, MENU_IDS.LOGIN_MENU_ID);

const addMyAccountSubMenuItem = (editorSDK: EditorSDK, options: EditorReadyOptions) =>
  addMyAccountMenuItem(editorSDK, options, MENU_IDS.SUB_MENU_ID);

export const addLoginBarMenuItems = async (editorSDK: EditorSDK, definitions: IntegrationApplicationWithWidgetId[]) =>
  addMenuItems(editorSDK, definitions, MENU_IDS.LOGIN_MENU_ID);

export const addMembersSubMenuItems = async (editorSDK: EditorSDK, definitions: IntegrationApplicationWithWidgetId[]) =>
  addMenuItems(editorSDK, definitions, MENU_IDS.SUB_MENU_ID);

export const addMyAccountMenuItemToMembersAreaMenus = async (editorSDK: EditorSDK, options: EditorReadyOptions) =>
  Promise.all([addMyAccountLoginMenuItem(editorSDK, options), addMyAccountSubMenuItem(editorSDK, options)]);
